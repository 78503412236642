import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div className="py-16px lg:pt-32px pr-4 mb-8px">
      <h1 className="uppercase text-sand-500 text-center text-3xl lg:text-5xl font-header tracking-wide antialiased subpixel-antialiased">
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
      <h2 className="text-sand-100 text-sm lg:text-base text-center italic leading-tight -mt-2 lg:-mt-4">
        “Waarom solo als het ook gezellig met z’n drieën kan”
      </h2>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
