import React from "react"

import { Link } from "gatsby"

import { IconContext } from "react-icons";
import { FiArrowRight } from "react-icons/fi";

const MenuItems = ({ alignLeft = true, home = false }) => (
    <IconContext.Provider value={{ className: "text-sand-100 text-xl lg:text-5xl ml-8px lg:ml-16px" }}>
        { home ? 
            <Link to="/" onClick={() => document.querySelector('body').classList.remove('menu-open')}>
                <div className=
                    { 
                        alignLeft
                        ? "text-left mb-5 lg:mb-8 flex justify-between" 
                        : "text-right mb-5 lg:mb-8 inline-flex justify-end" 
                    }
                >
                <div className=
                    { 
                    alignLeft
                        ? "flex flex-col items-start"
                        : "flex flex-col items-end"
                    }
                >
                    <h2 className="uppercase text-sand-100 text-xl lg:text-5xl font-header tracking-wide -mb-2 lg:-mb-4">Home</h2>
                    <h3 className="text-sm lg:text-lg text-sand-500 leading-tight">Kom meer te weten over de samenstelling van Classicurls</h3>
                </div>
                <FiArrowRight />
                </div>
            </Link>
        : "" }
        
        <Link to="/speellijst" onClick={() => document.querySelector('body').classList.remove('menu-open')}>
            <div className=
                  { 
                    alignLeft
                      ? "text-left mb-5 lg:mb-8 flex justify-between" 
                      : "text-right mb-5 lg:mb-8 inline-flex justify-end" 
                  }
            >
            <div className=
                { 
                alignLeft
                    ? "flex flex-col items-start"
                    : "flex flex-col items-end"
                }
            >
                <h2 className="uppercase text-sand-100 text-xl lg:text-5xl font-header tracking-wide -mb-2 lg:-mb-4">Speellijst</h2>
                <h3 className="text-sm lg:text-lg text-sand-500 leading-tight">Wanneer wij optreden en andere praktische informatie</h3>
            </div>
            <FiArrowRight />
            </div>
        </Link>

        <Link to="/media" onClick={() => document.querySelector('body').classList.remove('menu-open')}>
        <div className=
                  { 
                    alignLeft
                      ? "text-left mb-5 lg:mb-8 flex justify-between" 
                      : "text-right mb-5 lg:mb-8 inline-flex justify-end" 
                  }
            >
            <div className=
                { 
                alignLeft
                    ? "flex flex-col items-start"
                    : "flex flex-col items-end"
                }
            >
                <h2 className="uppercase text-sand-100 text-xl lg:text-5xl font-header tracking-wide -mb-2 lg:-mb-4">Beeldmateriaal</h2>
                <h3 className="text-sm lg:text-lg text-sand-500 leading-tight">Foto en videomateriaal van onze performances</h3>
            </div>
            <FiArrowRight />
            </div>
        </Link>

        <Link to="/contact" onClick={() => document.querySelector('body').classList.remove('menu-open')}>
        <div className=
                  { 
                    alignLeft
                      ? "text-left mb-5 lg:mb-8 flex justify-between" 
                      : "text-right mb-5 lg:mb-8 inline-flex justify-end" 
                  }
            >
            <div className=
                { 
                alignLeft
                    ? "flex flex-col items-start"
                    : "flex flex-col items-end"
                }
            >
                <h2 className="uppercase text-sand-100 text-xl lg:text-5xl font-header tracking-wide -mb-2 lg:-mb-4">Contact & Socials</h2>
                <h3 className="text-sm lg:text-lg text-sand-500 leading-tight">Kom in contact en vind ons op social media</h3>
            </div>
            <FiArrowRight />
            </div>
        </Link>
    </IconContext.Provider>
)

export default MenuItems